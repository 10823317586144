import { animate, style, transition, trigger } from '@angular/animations';

export const instanceOfError = (data: any): boolean => {
  if (!data) {
    return true;
  } else if ('err' in data || 'errorMessage' in data) {
    return true;
  } else if ('msg' in data) {
    return data.msg.indexOf('ERROR') > -1;
  }
};

export const instanceOfErrAttribute = (data: any): boolean => {
  if (!data) {
    return true;
  } else {
    return 'err' in data;
  }
};

export const ADMINISTRATOR = 'Administrador';
export const ADMINUSERS = 'Administrador de usuarios';
export const RESPCONTENT = 'Responsable de contenido';
export const ADMINCASES = 'Administrador de casos';
export const SUSCRIPTOR = 'Suscriptor';
export const CHIEFSUSCRIPTOR = 'Jefe de suscriptores';
export const DOCTOR = 'Médico';
export const CONSULTA = 'Consulta';
export const DRUGSTORAGE = 'Drug_storage';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))])
]);
